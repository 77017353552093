import React, {Component, useGlobal} from 'reactn';
import {TRANSLATION_EE} from "../../Constants/translation_ee";
import {TRANSLATION_EN} from "../../Constants/translation_en";
import axios from "axios";
import {CONFIG} from "../../Constants/config";
import {TRANSLATION_CR} from "../../Constants/translation_cr";
import {withNamespaces} from "react-i18next";
import {css} from "styled-components";
import swal from "sweetalert";

class DashboardPage extends Component {
    constructor(props) {
        super();
        this.state = {
            translations: [],
            name_a: props.t("A device"),
            name_b: props.t("B device"),
            change_a: false,
            change_b: false,
            modules: {
                statistic: 0,
                debug: 0,
                sensors: 0,
                status: 0,
                power: 0,
                users: 0,
            }
        };
    }

    componentDidMount() {
        console.log(this.global.user.data);
        this.fetchData();
        this.setState({modules: this.global.user.data.last_login[0]});
    }

    fetchData = () => {
        let endpoint = 'get_names?uuid=' + JSON.parse(localStorage.getItem('selectedDevice')).UUID;

        try {
            axios.get(CONFIG.API_URL_V2 + endpoint, {validateStatus: false})
                .then(res => {
                    if (parseInt(res.data.STATUS_CODE) === 200) {
                        this.setState({
                            name_a: res.data.DATA.A,
                            name_b: res.data.DATA.B,
                        })
                    }
                })
        } catch {
            this.timeout = null;
        }
    };

    changeA = () => {
        this.setState({change_a: true});
    };

    saveA = () => {
        this.setState({change_a: false});

        // SAVE POST
        axios.post(CONFIG.API_URL_V2 + "update_name_a", {'uuid':JSON.parse(localStorage.getItem('selectedDevice')).UUID, 'name': this.state.name_a}).then((res) => {this.fetchData()});
    };

    changeB = () => {
        this.setState({change_b: true});
    };

    saveB = () => {
        this.setState({change_b: false});

        // SAVE POST
        axios.post(CONFIG.API_URL_V2 + "update_name_b", {'uuid':JSON.parse(localStorage.getItem('selectedDevice')).UUID, 'name': this.state.name_b}).then((res) => {this.fetchData()});
    };

    render() {
        let company = this.global.user.data.company_name;

        const {t} = this.props;

        return (
            <div>
                <div className="row mb-0 row-eq-height">
                    <div className={"col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3"}>
                        <div className="card crm-data-card">
                            <div className="card-body animated fadeIn fast" style={{padding: 0}}>
                                {this.state.change_a &&
                                <button className="btn btn-success" onClick={this.saveA}>{t("Save")}</button>
                                }
                                {(!this.state.change_a && this.state.modules.users) &&
                                <button className="btn btn-info" onClick={this.changeA}>{t("Change name")}</button>
                                }
                                <h2 style={{padding: 5}} class={"text-center"}>{t("Side A")}</h2>
                                {!this.state.change_a &&
                                <p style={{padding: 5}}>{t("Device name")}: {this.state.name_a}</p>
                                }
                                {this.state.change_a &&
                                <p style={{padding: 5}}>{t("Device name")}: <input type="text" value={this.state.name_a}
                                                                            onChange={e => this.setState({name_a: e.target.value})}/>
                                </p>
                                }
                                <img src="images/spc3.png" alt="SPC3" width="100%"/>
                            </div>
                            {/*end card-body*/}
                        </div>
                        {/*end card*/}
                    </div>

                    <div className={"col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3"}>
                        <div className="card crm-data-card">
                            <div className="card-body animated fadeIn fast" style={{padding: 0}}>
                                {this.state.change_b &&
                                <button className="btn btn-success" onClick={this.saveB}>{t("Save")}</button>
                                }
                                {(!this.state.change_b && this.state.modules.users) &&
                                <button className="btn btn-info" onClick={this.changeB}>{t("Change name")}</button>
                                }
                                <h2 style={{padding: 5}} className={"text-center"}>{t("Side B")}</h2>
                                {!this.state.change_b &&
                                <p style={{padding: 5}}>{t("Device name")}: {this.state.name_b}</p>
                                }
                                {this.state.change_b &&
                                <p style={{padding: 5}}>{t("Device name")}: <input type="text" value={this.state.name_b}
                                                                            onChange={e => this.setState({name_b: e.target.value})}/>
                                </p>
                                }
                                <img src="images/spc3.png" alt="SPC3" width="100%"/>
                            </div>
                            {/*end card-body*/}
                        </div>
                        {/*end card*/}
                    </div>

                    <div className={"col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"}>
                        <div className="card crm-data-card">
                            <div className="card-body animated fadeIn fast">
                                <div className="text-right">
                                    <button className="btn btn-success" onClick={() => {
                                        swal(t("API key"), this.global.user.data.token , "success").then(r => {
                                            //console.log(this.global.user.data);
                                        });
                                    }}>{t("Copy API key")}</button>
                                </div>
                                <h2 style={{padding: 5}}
                                    className={"text-center"}>{t("Introduction to BMC")}</h2>
                                <div
                                    className='new-line'>{t("Introduction text")}</div>
                            </div>
                            {/*end card-body*/}
                        </div>
                        {/*end card*/}
                    </div>
                </div>

                <div className="row mb-0 row-eq-height">
                    {/*<DashboardMap />*/}
                </div>
            </div>
        );

    }
}

export default withNamespaces()(DashboardPage);
