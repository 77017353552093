import React, {Component} from 'reactn';
import axios from 'axios';
import {CONFIG} from "../../Constants/config";
import FancyCard from "../../Components/FancyCard/FancyCard";
import Moment from "react-moment";
import 'moment/locale/et';
import {withNamespaces} from "react-i18next";

class DebugPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            expanded: false,
            status: [],
            lastUpdated: null,
            outOfSync: false,
        };

        Moment.globalFormat = 'YYYY-M-D H:m:ss';
    };


    componentDidMount() {
        this.setGlobal({'isLoading': true});
        this.fetchData();
    }

    fetchData = async () => {
        if(!localStorage.getItem('selectedDevice')) {return}
        let endpoint = 'spc3_diagnostics_v2?uuid=' + JSON.parse(localStorage.getItem('selectedDevice')).UUID;

        try {
            axios.get(CONFIG.API_URL + endpoint, {validateStatus: false})
                .then(async res => {
                    if (parseInt(res.data.STATUS_CODE) === 200) {
                        await this.setState({status: res.data.data});
                        this.setGlobal({'isLoading': false});
                        setTimeout(this.fetchData, 5000);

                    }
                })
        } catch {
            this.timeout = null;
        }
    };

    orderReboot = async (name) => {
        let token = "8eba41be-cf95-43ea-9492-227c63d73297";

        let endpoint = 'spc3_reboot';

        await axios.post(
            CONFIG.API_URL + endpoint,
            {
                'name': name,
                'token': token
            }
        );
    }

    render() {
        const {t} = this.props;

        return (
            <div>
                <div className="row mb-0 row-eq-height">
                    <div className={"col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"}>
                        <div className="card crm-data-card">
                            <div className="card-body animated fadeIn fast" style={{padding: 0}}>
                                <img src={`data:image/jpeg;base64,${this.state.status.NanoADetection?.image_a}`} height="400" width="660"/>
                            </div>
                            {/*end card-body*/}
                        </div>
                        {/*end card*/}
                    </div>
                    <div className={"col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"}>
                        <div className="card crm-data-card">
                            <div className="card-body animated fadeIn fast" style={{padding: 0}}>
                                <img src={`data:image/jpeg;base64,${this.state.status.NanoADetection?.image_b}`} height="400" width="660"/>
                            </div>
                            {/*end card-body*/}
                        </div>
                        {/*end card*/}
                    </div>

                    <FancyCard
                        size={6}
                        title={t("Device A Machine vision status")}
                        value={this.state.status.NanoADetection?.running === "true" ? t("Operational") : t("Unoperational")}
                        subtitle={this.state.status.NanoADetection?.last_check ? t("Last check") + ": " + this.state.status.NanoADetection?.last_check : ""}
                        reboot={true}
                        error={this.state.status.NanoADetection?.running !== "true"}
                        rebootFn = {this.orderReboot}
                        rebootName = "NanoADetection"

                    />

                    <FancyCard
                        size={6}
                        title={t("Device B Machine vision status")}
                        value={this.state.status.NanoBDetection?.running === "true" ? t("Operational") : t("Unoperational")}
                        subtitle={this.state.status.NanoBDetection?.last_check ? t("Last check") + ": " + this.state.status.NanoBDetection?.last_check : ""}
                        reboot={true}
                        error={this.state.status.NanoBDetection?.running !== "true"}
                        rebootFn = {this.orderReboot}
                        rebootName = "NanoBDetection"
                    />

                    <FancyCard
                        size={6}
                        title={t("Device A main OS status")}
                        value={this.state.status.RPIA?.running === "true" ? t("Operational") : t("Unoperational")}
                        subtitle={this.state.status.RPIA?.last_check ? t("Last check") + ": " + this.state.status.RPIA?.last_check : ""}
                        reboot={true}
                        error={this.state.status.RPIA?.running !== "true"}
                        rebootFn = {this.orderReboot}
                        rebootName = "RPIA"
                    />

                    <FancyCard
                        size={6}
                        title={t("Device B main OS status")}
                        value={this.state.status.RPIB?.running === "true" ? t("Operational") : t("Unoperational")}
                        subtitle={this.state.status.RPIB?.last_check ? t("Last check") + ": " + this.state.status.RPIB?.last_check : ""}
                        reboot={true}
                        error={this.state.status.RPIB?.running !== "true"}
                        rebootFn = {this.orderReboot}
                        rebootName = "RPIB"
                    />

                </div>

            </div>
        );
    }
}

export default withNamespaces()(DebugPage);
