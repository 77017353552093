import React, {useGlobal} from 'reactn';
import {Redirect, Route} from "react-router-dom";

export const PrivateRoute = ({component: Component, ...rest}) => {
    const [isLoggedIn] = useGlobal('isLoggedIn');

    return (
        <Route
            {...rest}
            render={props =>
                isLoggedIn ? (
                    <Component {...props}/>
                ) : (
                    <Redirect
                        to={{
                            pathname: "/login",
                            state: {from: props.location}
                        }}
                    />
                )}
        />
    )
};
