import React, { Component, useGlobal } from "reactn";
import FancyCard from "../../Components/FancyCard/FancyCard";
import Chart from "react-apexcharts";
import Moment from "react-moment";
import axios from "axios";
import { CONFIG } from "../../Constants/config";
import * as moment from "moment";

import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css";
import Tooltip from "react-simple-tooltip";
import { css } from "styled-components"; // theme css file
import Switch from "react-ios-switch";
import { FormControl, InputGroup } from "react-bootstrap";
import ReactApexChart from "react-apexcharts";

import { withNamespaces } from "react-i18next";

import DateRangePicker from "react-daterange-picker";
import "react-daterange-picker/dist/css/react-calendar.css";

let source = axios.CancelToken.source();

class StatisticsPage extends Component {
  constructor(props) {
    super(props);

    // eslint-disable-next-line no-unused-vars
    source = axios.CancelToken.source();

    this.state = {
      speed_trigger: 0,
      data_url: null,
      dates: null,
      colors: ["#4d3a96", "#4576b5"],
      options: {
        chart: {
          id: "basic-bar",
        },
        xaxis: {
          categories: [
            props.t("Bicycle"),
            props.t("Motorbike"),
            props.t("Passenger Car"),
            props.t("Transporter"),
            props.t("Truck/Bus"),
            props.t("Long truck"),
          ],
        },
      },
      series: [
        {
          name: props.t("A->B"),
          data: [0, 0, 0, 0, 0, 0],
        },
        {
          name: props.t("B->A"),
          data: [0, 0, 0, 0, 0, 0],
        },
      ],
      sensors: [],
      lastUpdated: null,
      outOfSync: false,
      smallCharts: false,
      translations: [],
      currentDate: new Date().toISOString().slice(0, 10),
      today: new Date().toISOString().slice(0, 10),

      currentDateFull: new Date(),
      todayFull: new Date(),

      isToday: true,

      nullchart: [
        {
          name: "",
          data: [
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0,
          ],
        },
      ],

      // NEW LOGIC
      pedestrian_count_a_b: null,
      pedestrian_count_b_a: null,
      pedestrian_foul_count_a_b: null,
      pedestrian_foul_count_b_a: null,
      speedings_a_b: null,
      speedings_b_a: null,
      vehicles_a_b: null,
      vehicles_b_a: null,
      perc_a_b: null,
      perc_b_a: null,
      max_a_b: null,
      max_b_a: null,
      finished: false,
      paevaneVaade: false,
      ajalooMode: 5,
      startDate: null,
      endDate: null,
      seriesPie: [0, 0, 0, 0, 0, 0],
      seriesPie1: [0, 0, 0, 0, 0, 0],
      optionsPie: {
        chart: {
          width: 380,
          type: "pie",
        },
        labels: [
          props.t("Bicycle"),
          props.t("Motorbike"),
          props.t("Passenger Car"),
          props.t("Transporter"),
          props.t("Truck/Bus"),
          props.t("Long truck"),
        ],
      },

      seriesPedestrians: [
        {
          name: props.t("A->B"),
          data: [15, 41, 35, 51, 49, 62, 69, 91, 148],
        },
        {
          name: props.t("B->A"),
          data: [10, 41, 35, 51, 49, 62, 69, 91, 148],
        },
      ],

      optionsPedestrians: {
        chart: {
          height: 350,
          type: "line",
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
        },
        grid: {
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        xaxis: {
          categories: [
            props.t("Jan"),
            props.t("Feb"),
            props.t("Mar"),
            props.t("Apr"),
            props.t("May"),
            props.t("Jun"),
            props.t("Jul"),
            props.t("Aug"),
            props.t("Sep"),
            props.t("Oct"),
            props.t("Nov"),
            props.t("Dec"),
          ],
        },
      },

      seriesRadar: [
        {
          name: props.t("A->B"),
          data: [15, 41, 35, 51, 49, 62, 69, 91, 148],
        },
        {
          name: props.t("B->A"),
          data: [10, 41, 35, 51, 49, 62, 69, 91, 148],
        },
      ],

      optionsRadar: {
        chart: {
          height: 350,
          type: "line",
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
        },
        grid: {
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        xaxis: {
          categories: [
            props.t("Jan"),
            props.t("Feb"),
            props.t("Mar"),
            props.t("Apr"),
            props.t("May"),
            props.t("Jun"),
            props.t("Jul"),
            props.t("Aug"),
            props.t("Sep"),
            props.t("Oct"),
            props.t("Nov"),
            props.t("Dec"),
          ],
        },
      },

      showPedestrianGraph: false,
      showRadarGraph: false,
    };

    Moment.globalFormat = "YYYY-M-D H:m:ss";
  }

  fetchSpeedTrigger = () => {
    let endpoint =
      "get_speed_trigger?uuid=" +
      JSON.parse(localStorage.getItem("selectedDevice")).UUID;

    try {
      axios
        .get(CONFIG.API_URL_V2 + endpoint, { validateStatus: false })
        .then((res) => {
          if (parseInt(res.data.STATUS_CODE) === 200) {
            this.setState({
              speed_trigger: res.data.DATA.speed_trigger,
            });
          }
        });
    } catch {
      this.timeout = null;
    }
  };

  onSelect = async (dates) => {
    this.setState({ dates });
    let startDate = dates.start.format("YYYY-MM-DD");
    let endDate = dates.end.format("YYYY-MM-DD");

    await this.setState({
      ajalooMode: 6,
      startDate: startDate,
      endDate: endDate,
    });

    this.fetchData();
  };

  componentWillUnmount() {
    clearInterval(this.timeout);
  }

  componentDidMount() {
    this.setGlobal({ isLoading: true });
    this.fetchSpeedTrigger();
    this.fetchData();
    this.timeout = setInterval(() => {
      if (this.state.finished && this.state.paevaneVaade) {
        this.setState({ finished: false });
        this.fetchData();
      }
    }, 100000);
    this.calculateStartAndEnd(this.state.ajalooMode);
  }

  handleSelect(ranges) {
    console.log(ranges);
  }

  selectionRange = {
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  };

  fetchDataNew = async () => {
    if (!localStorage.getItem("selectedDevice")) {
      return;
    }
    if (!this.state.paevaneVaade) {
      let start = this.state.startDate;
      let end = this.state.endDate;
      if (!start || !end) {
        return;
      }
      let endpoint =
        "spc3_statistics?from=" +
        start +
        "&to=" +
        end +
        "&uuid=" +
        JSON.parse(localStorage.getItem("selectedDevice")).UUID;
      let endpoint2 =
        "spc3_statistics_csv?from=" +
        start +
        "&to=" +
        end +
        "&uuid=" +
        JSON.parse(localStorage.getItem("selectedDevice")).UUID;

      this.setGlobal({ isLoading: true });

      this.setState({ data_url: CONFIG.API_URL_V2 + endpoint2 });

      try {
        axios
          .get(CONFIG.API_URL_V2 + endpoint, { validateStatus: false })
          .then((res) => {
            if (parseInt(res.data.STATUS_CODE) === 200) {
              if (res.data.DATA.pedestrians_data) {
                let pedestrians = res.data.DATA.pedestrians_data;

                console.log(pedestrians);

                this.setState({
                  pedestrian_count_a_b: {
                    pedestrian_count: pedestrians.sum_crossing_pihlakodu,
                    graph: [],
                  },
                });
                this.setState({
                  pedestrian_count_b_a: {
                    pedestrian_count: pedestrians.sum_crossing_lasteaia,
                    graph: [],
                  },
                });
                this.setState({
                  pedestrian_foul_count_a_b: {
                    pedestrian_count: pedestrians.sum_foul_pihlakodu,
                    graph: [],
                  },
                });
                this.setState({
                  pedestrian_foul_count_b_a: {
                    pedestrian_count: pedestrians.sum_foul_lasteaia,
                    graph: [],
                  },
                });

                let datesForPedestrians = [];
                let pedestriansAB = [];
                let pedestriansBA = [];

                console.log(pedestrians.graph_crossing_pihlakodu);
                console.log(pedestrians.graph_crossing_lasteaia);

                if (pedestrians.graph_crossing_pihlakodu) {
                  pedestrians.graph_crossing_pihlakodu.forEach(function (row) {
                    datesForPedestrians.push(row.date);
                    pedestriansAB.push(row.count);
                  });
                }

                if (pedestrians.graph_crossing_lasteaia) {
                  pedestrians.graph_crossing_lasteaia.forEach(function (row) {
                    pedestriansBA.push(row.count);
                  });
                }

                let seriesPedestrians = [
                  {
                    name: "A->B",
                    data: pedestriansAB,
                  },
                  {
                    name: "B->A",
                    data: pedestriansBA,
                  },
                ];

                let optionsPedestrians = {
                  chart: {
                    height: 350,
                    type: "line",
                    zoom: {
                      enabled: false,
                    },
                  },
                  dataLabels: {
                    enabled: false,
                  },
                  stroke: {
                    curve: "straight",
                  },
                  grid: {
                    row: {
                      colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
                      opacity: 0.5,
                    },
                  },
                  xaxis: {
                    categories: datesForPedestrians,
                  },
                };

                this.setState({
                  seriesPedestrians: seriesPedestrians,
                  optionsPedestrians: optionsPedestrians,
                });

                this.setState({ showPedestrianGraph: true });
              }

              if (res.data.DATA.radar_data) {
                let radar = res.data.DATA.radar_data;

                this.setState({
                  vehicles_a_b: radar.A_B.total,
                  vehicles_b_a: radar.B_A.total,
                });

                this.setState({
                  speedings_a_b: {
                    speedings_a_b: radar.A_B.speedings,
                    graph: [],
                  },
                });
                this.setState({
                  speedings_b_a: {
                    speedings_b_a: radar.B_A.speedings,
                    graph: [],
                  },
                });
                this.setState({
                  max_a_b: {
                    max_a_b: radar.A_B.max_speed,
                    graph: [],
                  },
                });
                this.setState({
                  max_b_a: {
                    max_b_a: radar.B_A.max_speed,
                    graph: [],
                  },
                });
                this.setState({
                  seriesPie: radar.A_B.pie_chart,
                  seriesPie1: radar.B_A.pie_chart,
                });

                let datesForRadar = [];
                let radarAB = [];
                let radarBA = [];

                if (radar.A_B.statistics) {
                  radar.A_B.statistics.forEach(function (row) {
                    datesForRadar.push(row.date);
                    radarAB.push(row.count);
                  });
                }

                if (radar.B_A.statistics) {
                  radar.B_A.statistics.forEach(function (row) {
                    radarBA.push(row.count);
                  });
                }

                let seriesRadar = [
                  {
                    name: "A->B",
                    data: radarAB,
                  },
                  {
                    name: "B->A",
                    data: radarBA,
                  },
                ];

                let optionsRadar = {
                  chart: {
                    height: 350,
                    type: "line",
                    zoom: {
                      enabled: false,
                    },
                  },
                  dataLabels: {
                    enabled: false,
                  },
                  stroke: {
                    curve: "straight",
                  },
                  grid: {
                    row: {
                      colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
                      opacity: 0.5,
                    },
                  },
                  xaxis: {
                    categories: datesForRadar,
                  },
                };

                this.setState({
                  seriesRadar: seriesRadar,
                  optionsRadar: optionsRadar,
                });
              }

              this.setGlobal({ isLoading: false });
            } else {
              this.setGlobal({ isLoading: false });
            }
          })
          .catch(() => {
            this.setGlobal({ isLoading: false });
          });
      } catch {}
    } else {
      this.setGlobal({ isLoading: true });

      let end = this.state.currentDate;

      var date = new Date();

      const today = new Date(end).getDate();

      let start = moment(date.setDate(today - 1)).format("YYYY-MM-DD 20:59:59");

      if (!start || !end) {
        return;
      }
      //let endpoint = 'spc3_flow_' + this.global.user.data.company_name.toLowerCase() + '_history_new?from=' + start + '&to=' + end;
      let endpoint =
        "spc3_statistics?from=" +
        start +
        "&to=" +
        end +
        "&uuid=" +
        JSON.parse(localStorage.getItem("selectedDevice")).UUID;

      this.setGlobal({ isLoading: true });

      try {
        axios
          .get(CONFIG.API_URL_V2 + endpoint, { validateStatus: false })
          .then((res) => {
            if (parseInt(res.data.STATUS_CODE) === 200) {
              if (res.data.DATA.pedestrians_data) {
                let pedestrians = res.data.DATA.pedestrians_data;
                let smallChartsIn = res.data.DATA.small_charts;

                if (smallChartsIn) {
                  this.setState({
                    smallCharts: smallChartsIn,
                  });
                }

                console.log(this.state.smallCharts);

                this.setState({
                  pedestrian_count_a_b: {
                    pedestrian_count: pedestrians.sum_crossing_pihlakodu,
                    graph: [],
                  },
                });
                this.setState({
                  pedestrian_count_b_a: {
                    pedestrian_count: pedestrians.sum_crossing_lasteaia,
                    graph: [],
                  },
                });
                this.setState({
                  pedestrian_foul_count_a_b: {
                    pedestrian_count: pedestrians.sum_foul_pihlakodu,
                    graph: [],
                  },
                });
                this.setState({
                  pedestrian_foul_count_b_a: {
                    pedestrian_count: pedestrians.sum_foul_lasteaia,
                    graph: [],
                  },
                });
              }

              if (res.data.DATA.radar_data) {
                let radar = res.data.DATA.radar_data;

                this.setState({
                  vehicles_a_b: radar.A_B.total,
                  vehicles_b_a: radar.B_A.total,
                });

                this.setState({
                  speedings_a_b: {
                    speedings_a_b: radar.A_B.speedings,
                    graph: [],
                  },
                });
                this.setState({
                  speedings_b_a: {
                    speedings_b_a: radar.B_A.speedings,
                    graph: [],
                  },
                });
                this.setState({
                  max_a_b: {
                    max_a_b: radar.A_B.max_speed,
                    graph: [],
                  },
                });
                this.setState({
                  max_b_a: {
                    max_b_a: radar.B_A.max_speed,
                    graph: [],
                  },
                });
                this.setState({
                  seriesPie: radar.A_B.pie_chart,
                  seriesPie1: radar.B_A.pie_chart,
                });

                let datesForRadar = [];
                let radarAB = [];
                let radarBA = [];

                if (radar.A_B.statistics) {
                  radar.A_B.statistics.forEach(function (row) {
                    datesForRadar.push(row.date);
                    radarAB.push(row.count);
                  });
                }

                if (radar.B_A.statistics) {
                  radar.B_A.statistics.forEach(function (row) {
                    radarBA.push(row.count);
                  });
                }

                let seriesRadar = [
                  {
                    name: "A->B",
                    data: radarAB,
                  },
                  {
                    name: "B->A",
                    data: radarBA,
                  },
                ];

                let optionsRadar = {
                  chart: {
                    height: 350,
                    type: "line",
                    zoom: {
                      enabled: false,
                    },
                  },
                  dataLabels: {
                    enabled: false,
                  },
                  stroke: {
                    curve: "straight",
                  },
                  grid: {
                    row: {
                      colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
                      opacity: 0.5,
                    },
                  },
                  xaxis: {
                    categories: datesForRadar,
                  },
                };

                this.setState({
                  seriesRadar: seriesRadar,
                  optionsRadar: optionsRadar,
                });
              }

              this.setGlobal({ isLoading: false });
            } else {
              this.setGlobal({ isLoading: false });
            }
          })
          .catch(() => {
            this.setGlobal({ isLoading: false });
          });
      } catch {}
    }
  };

  fetchData = async () => {
    return this.fetchDataNew();
  };

  toggle = async () => {
    this.fetchData();
  };

  prevDay = async () => {
    this.setGlobal({ isLoading: true });
    this.state.currentDateFull.setDate(
      this.state.currentDateFull.getDate() - 1
    );
    await this.setState({
      currentDate: this.state.currentDateFull.toISOString().slice(0, 10),
      isToday: false,
    });
    this.fetchData();

    console.log(this.state.isToday);
  };

  nextDay = async () => {
    this.setGlobal({ isLoading: true });
    this.state.currentDateFull.setDate(
      this.state.currentDateFull.getDate() + 1
    );
    await this.setState({
      currentDate: this.state.currentDateFull.toISOString().slice(0, 10),
    });

    this.fetchData();

    if (this.state.currentDate === this.state.today) {
      this.setState({ isToday: true });
    }

    console.log(this.state.isToday);
  };

  calculateStartAndEnd = async (ajalooMode) => {
    await this.setState({ ajalooMode: ajalooMode });

    let endDate = moment().format("YYYY-MM-DD");

    switch (this.state.ajalooMode) {
      case 1:
        // aasta algusest
        await this.setState({
          ajalooMode: ajalooMode,
          startDate: "2021-01-01",
          endDate: endDate,
        });

        this.fetchData();
        break;
      case 2:
        // Viimased 3 kuud
        var d = new Date();
        let startDate = moment(d.setMonth(d.getMonth() - 3)).format(
          "YYYY-MM-DD"
        );
        await this.setState({
          ajalooMode: ajalooMode,
          startDate: startDate,
          endDate: endDate,
        });

        this.fetchData();

        break;
      case 3:
        // Eelmine kuu
        var date = new Date();
        var monthStartDay = moment(
          new Date(date.getFullYear(), date.getMonth() - 1, 1)
        ).format("YYYY-MM-DD");
        var monthEndDay = moment(
          new Date(date.getFullYear(), date.getMonth(), 0)
        ).format("YYYY-MM-DD");
        await this.setState({
          ajalooMode: ajalooMode,
          startDate: monthStartDay,
          endDate: monthEndDay,
        });

        this.fetchData();

        break;
      case 4:
        // Eelmine nädal
        var d = new Date();
        var to = moment(
          d.setTime(
            d.getTime() - (d.getDay() ? d.getDay() : 7) * 24 * 60 * 60 * 1000
          )
        ).format("YYYY-MM-DD");
        var from = moment(
          d.setTime(d.getTime() - 6 * 24 * 60 * 60 * 1000)
        ).format("YYYY-MM-DD");

        await this.setState({
          ajalooMode: ajalooMode,
          startDate: from,
          endDate: to,
        });

        this.fetchData();

        break;
      case 5:
        // Jooksev nädal
        date = new Date();
        const today = date.getDate();
        var dayOfTheWeek = date.getDay();

        const newDate = moment(
          date.setDate(today - (dayOfTheWeek || 6))
        ).format("YYYY-MM-DD 20:59:59");

        await this.setState({
          ajalooMode: ajalooMode,
          startDate: newDate,
          endDate: endDate,
        });

        this.fetchData();
        break;
      case 6:
        // get start date
        // get end date

        //await this.setState({ajalooMode: ajalooMode, startDate: newDate, endDate: endDate});
        break;
    }
  };

  render() {
    const { t } = this.props;
    return (
      <div>
        <div className="row">
          <div className={"col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"}>
            <div className="card crm-data-card">
              <div className="text-right"></div>
              <div className="card-body animated fadeIn fast text-center">
                <div className="row text-center">
                  <div
                    className={
                      "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center"
                    }
                  >
                    <InputGroup className="input-group w-25 mx-auto">
                      <InputGroup.Text id="inputGroup-sizing-sm">
                        {t("History view")}
                      </InputGroup.Text>
                      &nbsp;&nbsp;
                      <Switch
                        checked={this.state.paevaneVaade}
                        onChange={(checked) => {
                          this.setState({ paevaneVaade: checked });
                          this.toggle();
                        }}
                      />
                      &nbsp;&nbsp;
                      <InputGroup.Text>{t("Daily view")}</InputGroup.Text>
                    </InputGroup>
                  </div>
                </div>
                {/* end row*/}
              </div>
              {/*end card-body*/}
            </div>
            {/*end card*/}
          </div>
        </div>
        {this.state.paevaneVaade && (
          <div className="row">
            <div className={"col-12 col-sm-12 col-md-6 col-lg-12 col-xl-12"}>
              <div className="card crm-data-card">
                <div className="text-right"></div>
                <div className="card-body animated fadeIn fast">
                  <div className="row">
                    <div
                      className={
                        "col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 text-left"
                      }
                      style={{ cursor: "pointer" }}
                      onClick={this.prevDay}
                    >
                      <i className={"mdi mdi-arrow-left"} />{" "}
                      {this.state.translations.back}
                    </div>
                    <div
                      className={
                        "col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 text-center"
                      }
                    >
                      <Moment format="DD.MM.yyyy">
                        {this.state.currentDate}
                      </Moment>
                    </div>
                    {this.state.currentDate !== this.state.today && (
                      <div
                        className={
                          "col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 text-right"
                        }
                        style={{ cursor: "pointer" }}
                        onClick={this.nextDay}
                      >
                        {this.state.translations.forward}{" "}
                        <i className={"mdi mdi-arrow-right"} />
                      </div>
                    )}
                  </div>
                  {/* end row*/}
                </div>
                {/*end card-body*/}
              </div>
              {/*end card*/}
            </div>
          </div>
        )}
        {!this.state.paevaneVaade && (
          <div className="row">
            <div className={"col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"}>
              <div className="card crm-data-card">
                <div className="text-right"></div>
                <div className="card-body animated fadeIn fast">
                  <div className="row">
                    <div
                      className={
                        "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center"
                      }
                    >
                      <a
                        href="#"
                        onClick={() => {
                          this.setState({ ajalooMode: 6 });
                          this.calculateStartAndEnd(6);
                        }}
                      >
                        {this.state.ajalooMode === 6 && (
                          <b> {t("Choose period")} </b>
                        )}
                        {this.state.ajalooMode !== 6 && (
                          <span> {t("Choose period")} </span>
                        )}
                      </a>
                      |
                      <a
                        href="#"
                        onClick={() => {
                          this.setState({ ajalooMode: 1 });
                          this.calculateStartAndEnd(1);
                        }}
                      >
                        {this.state.ajalooMode === 1 && (
                          <b> {t("Beginning of the year")} </b>
                        )}
                        {this.state.ajalooMode !== 1 && (
                          <span> {t("Beginning of the year")} </span>
                        )}
                      </a>
                      |
                      <a
                        href="#"
                        onClick={() => {
                          this.setState({ ajalooMode: 2 });
                          this.calculateStartAndEnd(2);
                        }}
                      >
                        {this.state.ajalooMode === 2 && (
                          <b> {t("Last 3 months")} </b>
                        )}{" "}
                        {this.state.ajalooMode !== 2 && (
                          <span> {t("Last 3 months")} </span>
                        )}
                      </a>
                      |
                      <a
                        href="#"
                        onClick={() => {
                          this.setState({ ajalooMode: 3 });
                          this.calculateStartAndEnd(3);
                        }}
                      >
                        {this.state.ajalooMode === 3 && (
                          <b> {t("Last month")} </b>
                        )}{" "}
                        {this.state.ajalooMode !== 3 && (
                          <span> {t("Last month")} </span>
                        )}
                      </a>
                      |
                      <a
                        href="#"
                        onClick={() => {
                          this.setState({ ajalooMode: 4 });
                          this.calculateStartAndEnd(4);
                        }}
                      >
                        {this.state.ajalooMode === 4 && (
                          <b> {t("Last week")} </b>
                        )}
                        {this.state.ajalooMode !== 4 && (
                          <span> {t("Last week")} </span>
                        )}
                      </a>
                      |
                      <a
                        href="#"
                        onClick={() => {
                          this.setState({ ajalooMode: 5 });
                          this.calculateStartAndEnd(5);
                        }}
                      >
                        {this.state.ajalooMode === 5 && (
                          <b> {t("Current week")} </b>
                        )}
                        {this.state.ajalooMode !== 5 && (
                          <span> {t("Current week")} </span>
                        )}
                      </a>
                      {this.state.ajalooMode === 6 && (
                        <div>
                          <DateRangePicker
                            value={this.state.dates}
                            onSelect={this.onSelect}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  {/* end row*/}
                </div>
                {/*end card-body*/}
              </div>
              {/*end card*/}
            </div>
          </div>
        )}
        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <div class="row">
            <div class="col-6">
              {this.state.paevaneVaade && (
                <h4 className="page-title">
                  {t("Period")}:{" "}
                  <Moment format="DD.MM.yyyy">{this.state.currentDate}</Moment>
                </h4>
              )}
              {!this.state.paevaneVaade && (
                <h4 className="page-title">
                  {t("Period")}:{" "}
                  <Moment format="DD.MM.yyyy">{this.state.startDate}</Moment> -{" "}
                  <Moment format="DD.MM.yyyy">{this.state.endDate}</Moment>
                </h4>
              )}
            </div>

            <div class="col-6 text-right pull-right">
              <button
                class="btn btn-info"
                onClick={() => {
                  this.setGlobal({ isLoading: true });
                  setTimeout(() => {this.setGlobal({ isLoading: false });}, 10000);
                  window.location.href = this.state.data_url;
                }}
              >
                {t("Export data")}
              </button>
            </div>
          </div>
        </div>
        <div className="row">
          <div
            className={"col-sm-12 col-md-12 col-lg-8 col-xl-8"}
            style={{ marginBottom: "25px" }}
          >
            <div className="row">
              <FancyCard
                size={6}
                title={t("Pedestrian count A->B")}
                value={
                  this.state.pedestrian_count_a_b?.pedestrian_count
                    ? this.state.pedestrian_count_a_b?.pedestrian_count
                    : 0
                }
                subtitle={this.state.translations.crossings_counted}
                icon={"mdi-arrow-up-down-bold-outline"}
                iconClass={"bg-soft-secondary"}
                tooltip={t("Pedestrian count A->B tooltip")}
                isToday={this.state.isToday}
                showChart={this.state.paevaneVaade && this.state.smallCharts}
                chart={[
                  {
                    name: "",
                    data: this.state.smallCharts.A,
                  },
                ]}
              />
              <FancyCard
                size={6}
                title={t("Pedestrian count B->A")}
                value={
                  this.state.pedestrian_count_b_a?.pedestrian_count
                    ? this.state.pedestrian_count_b_a?.pedestrian_count
                    : 0
                }
                subtitle={this.state.translations.crossings_counted}
                icon={"mdi-arrow-up-down-bold-outline"}
                iconClass={"bg-soft-secondary"}
                tooltip={t("Pedestrian count B->A tooltip")}
                isToday={this.state.isToday}
                showChart={this.state.paevaneVaade && this.state.smallCharts}
                chart={[
                  {
                    name: "",
                    data: this.state.smallCharts.B,
                  },
                ]}
                chartColor={["#4aec6c"]}
              />
            </div>
            <div className="row">
              <FancyCard
                size={6}
                title={t("Foul crossing count A->B")}
                value={
                  this.state.pedestrian_foul_count_a_b?.pedestrian_count
                    ? this.state.pedestrian_foul_count_a_b?.pedestrian_count
                    : 0
                }
                subtitle={this.state.translations.crossings_counted}
                icon={"mdi-arrow-up-down-bold-outline"}
                iconClass={"bg-soft-secondary"}
                tooltip={t("Foul crossing count A->B tooltip")}
                isToday={this.state.isToday}
                showChart={this.state.paevaneVaade && this.state.smallCharts}
                chart={[
                  {
                    name: "",
                    data: this.state.smallCharts.FA,
                  },
                ]}
              />
              <FancyCard
                size={6}
                title={t("Foul crossing count B->A")}
                value={
                  this.state.pedestrian_foul_count_b_a?.pedestrian_count
                    ? this.state.pedestrian_foul_count_b_a?.pedestrian_count
                    : 0
                }
                subtitle={this.state.translations.crossings_counted}
                icon={"mdi-arrow-up-down-bold-outline"}
                iconClass={"bg-soft-secondary"}
                tooltip={t("Foul crossing count B->A tooltip")}
                isToday={this.state.isToday}
                showChart={this.state.paevaneVaade && this.state.smallCharts}
                chart={[
                  {
                    name: "",
                    data: this.state.smallCharts.FB,
                  },
                ]}
                chartColor={["#4aec6c"]}
              />
            </div>
            <div className="row">
              <FancyCard
                size={6}
                title={t("Vehicles count A->B")}
                value={this.state.vehicles_a_b ? this.state.vehicles_a_b : 0}
                icon={"mdi-arrow-up-down-bold-outline"}
                iconClass={"bg-soft-secondary"}
                isToday={this.state.isToday}
              />
              <FancyCard
                size={6}
                title={t("Vehicles count B->A")}
                value={this.state.vehicles_b_a ? this.state.vehicles_b_a : 0}
                icon={"mdi-arrow-up-down-bold-outline"}
                iconClass={"bg-soft-secondary"}
                isToday={this.state.isToday}
              />
            </div>
            <div className="row">
              <FancyCard
                size={6}
                title={
                  t("Speedings") + " " + this.state.speed_trigger + " km/h"
                }
                value={
                  (this.state.speedings_a_b?.speedings_a_b
                    ? this.state.speedings_a_b?.speedings_a_b
                    : 0) +
                  (this.state.speedings_b_a?.speedings_b_a
                    ? this.state.speedings_b_a?.speedings_b_a
                    : 0) +
                  " " +
                  t("Total")
                }
                value1={
                  (this.state.speedings_a_b?.speedings_a_b
                    ? this.state.speedings_a_b?.speedings_a_b
                    : 0) +
                  " " +
                  t("Side A")
                }
                value2={
                  (this.state.speedings_b_a?.speedings_b_a
                    ? this.state.speedings_b_a?.speedings_b_a
                    : 0) +
                  " " +
                  t("Side B")
                }
                value_small={true}
                icon={"mdi-speedometer"}
                iconClass={"bg-danger text-white"}
                tooltip={t("Speedings tooltip")}
                isToday={this.state.isToday}
                showChart={this.state.paevaneVaade}
                chart={[
                  {
                    name: this.props.t("Side A"),
                    data: this.state.speedings_a_b?.graph ?? [],
                  },
                  {
                    name: this.props.t("Side B"),
                    data: this.state.speedings_b_a?.graph ?? [],
                  },
                ]}
              />

              <FancyCard
                size={6}
                title={t("Maximum speed")}
                value1={
                  (this.state.max_a_b?.max_a_b
                    ? this.state.max_a_b?.max_a_b
                    : "0") +
                  " km/h " +
                  t("Side A")
                }
                value2={
                  (this.state.max_b_a?.max_b_a
                    ? this.state.max_b_a?.max_b_a
                    : "0") +
                  " km/h " +
                  t("Side B")
                }
                value_small={true}
                icon={"mdi-arrow-up-down-bold-outline"}
                iconClass={"bg-danger text-white"}
                isToday={this.state.isToday}
                showChart={this.state.paevaneVaade}
                tooltip={t("Maximum speed tooltip")}
                chart={[
                  {
                    name: this.props.t("Side A"),
                    data: this.state.max_a_b?.graph ?? [],
                  },
                  {
                    name: this.props.t("Side B"),
                    data: this.state.max_b_a?.graph ?? [],
                  },
                ]}
              />
            </div>
          </div>
          {this.state.optionsPie && (
            <div className={"col-sm-12 col-md-12 col-lg-4 col-xl-4"}>
              <div className="row">
                <div className={"col-12"} style={{ marginBottom: "50px" }}>
                  <div className="card crm-data-card h-100">
                    <div className="text-right"></div>
                    <div className="card-body animated fadeIn fast">
                      <h4 className="header-title mt-0">
                        {t("Radar classification A->B")}
                      </h4>
                      <div className="row">
                        <ReactApexChart
                          options={this.state.optionsPie}
                          series={this.state.seriesPie}
                          type="pie"
                          width={380}
                        />
                      </div>
                      <br />
                      <br />
                      <br />
                      <br />
                      <h4
                        className="header-title mt-0"
                        style={{ marginTop: "50px" }}
                      >
                        {t("Radar classification B->A")}
                      </h4>
                      <div className="row">
                        <ReactApexChart
                          options={this.state.optionsPie}
                          series={this.state.seriesPie1}
                          type="pie"
                          width={380}
                        />
                      </div>
                    </div>
                    {/* end row*/}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        {!this.state.paevaneVaade && this.state.showPedestrianGraph && (
          <div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <h4 className="page-title">{t("Pedestrian statistics")}</h4>
              <div className="card crm-data-card">
                <div className="card-body animated fadeIn fast">
                  <h4 className="header-title mt-0">
                    {t("Pedestrian statistics")}
                  </h4>
                  <ReactApexChart
                    options={this.state.optionsPedestrians}
                    series={this.state.seriesPedestrians}
                    type="bar"
                    height={350}
                  />
                </div>
              </div>
              {/*end card-body*/}
            </div>
            {/*end card*/}
          </div>
        )}
        {this.state.radar_options && this.state.paevaneVaade && (
          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <h4 className="page-title">{t("Radar statistics")}</h4>
            <div className="card crm-data-card">
              <div className="card-body animated fadeIn fast">
                <h4 className="header-title mt-0">{t("Radar statistics")}</h4>
                <Chart
                  options={this.state.radar_options}
                  series={this.state.series}
                  type="bar"
                  height="300"
                  colors={this.state.colors}
                />
              </div>
              {/*end card-body*/}
            </div>
            {/*end card*/}
          </div>
        )}
        {/* end col*/}

        {!this.state.paevaneVaade && (
          <div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <h4 className="page-title">{t("Radar statistics")}</h4>
              <div className="card crm-data-card">
                <div className="card-body animated fadeIn fast">
                  <h4 className="header-title mt-0">{t("Radar statistics")}</h4>
                  <ReactApexChart
                    options={this.state.optionsRadar}
                    series={this.state.seriesRadar}
                    type="bar"
                    height={350}
                  />
                </div>
              </div>
              {/*end card-body*/}
            </div>
            {/*end card*/}
          </div>
        )}
      </div>
    );
  }
}

export default withNamespaces()(StatisticsPage);
