import React, { Component, useGlobal } from "reactn";
import "./MainPage.css";
import { Link } from "react-router-dom";
import DashboardPage from "../Dashboard/DashboardPage";
import StatisticsPage from "../Statistics/StatisticsPage";
import SensorPage from "../Sensors/SensorPage";
import PowerPage from "../Power/PowerPage";
import ContactPage from "../Contact/ContactPage";
import StatusPage from "../Status/StatusPage";
import DebugPage from "../Debug/DebugPage";
import UserPage from "../User/UserPage";
import { withNamespaces } from "react-i18next";
import i18n from "../../i18n";
import { CONFIG } from "../../Constants/config";
import axios from "axios";
import PasswordChangeModal from "../../Components/PasswordChangeModal/PasswordChangeModal";
import SetSpeedModal from "../../Components/SetSpeedModal/SetSpeedModal";

import Badge from "@material-ui/core/Badge";
import NotificationsPage from "../Notifications/NotificationsPage";

class MainPage extends Component {
  constructor(props) {
    super();
    this.state = {
      mobileMenuOpen: false,
      displayLangSelect: true,
      displayDeviceSelect: true,
      route: "/",
      title: props.t("Dashboard"),
      component: "dashboard",
      modules: {
        statistic: 0,
        debug: 0,
        sensors: 0,
        status: 0,
        power: 0,
        users: 0,
      },
      devices: null,
      refreshPW: 0,
      refreshModalOpen: false,
      displaySettings: false,
      displayNotifications: false,
      speedModalOpen: false,
      modification: "M",
      notificationCount: 0
    };
  }

  fetchNotificationsCount = () => {
    let endpoint = 'notifications_count?uuid=' + JSON.parse(localStorage.getItem('selectedDevice')).UUID + "&uid="+this.global.user?.data?.id;

    try {
      axios.get(CONFIG.API_URL_V2 + endpoint, {validateStatus: false})
          .then(res => {
            if (parseInt(res.data.STATUS_CODE) === 200) {
              this.setState({notificationCount: res.data.DATA});

              setTimeout(() => this.fetchNotificationsCount(), 2000);
            }
          });
    } catch {
      this.timeout = null;
    }
  }

  changeLanguage = (lng) => {
    localStorage.setItem("i18nextLng", lng);
    i18n.changeLanguage(lng);
    this.setState({ displayLangSelect: false });
  };

  setRefreshModelOpen = () => {
    this.setState({ refreshModalOpen: !this.state.refreshModalOpen });
  };

  setSpeedModelOpen = () => {
    this.setState({ speedModalOpen: !this.state.speedModalOpen });
  };

  overrideForce = () => {
    localStorage.setItem("passwordChanged", true);
    this.setState({ refreshPW: 0 });
  };

  async componentDidMount() {
    this.setState({ mobileMenuOpen: false });
    this.setState({ route: this.props.location.pathname });
    this.setState({
      title: this.props.t(
        this.props.location.pathname.replace("/", "").charAt(0).toUpperCase() +
          this.props.location.pathname.replace("/", "").slice(1)
      ),
    });
    this.setState({ component: this.props.location.pathname.replace("/", "") });
    this.setState({ modules: this.global.user.data.last_login[0] });
    console.log("Fetching");
    await this.fetchDevices();
    console.log("done fetch");
    if (this.props.location.pathname === "/") {
      this.showDashboardPage();
    }

    document.body.classList.remove("account-body");
    document.body.classList.remove("login-page");

    setTimeout(() => {
      this.setState({ displayLangSelect: true });
    }, 500);

    console.log("PW CHANGED" + localStorage.getItem("passwordChanged"));

    if (!localStorage.getItem("passwordChanged")) {
      await this.setState({ refreshPW: this.global.user.data.init_password });

      if (this.state.refreshPW) {
        await this.setState({ refreshModalOpen: this.state.refreshPW });
      }

      console.log("RESET " + this.state.refreshPW);
    }

    if (!localStorage.getItem("initRefresh")) {
      localStorage.setItem("initRefresh", true);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }

    this.fetchNotificationsCount();
  }

  setSelectedDevice = (x) => {
    localStorage.setItem("selectedDevice", JSON.stringify(x));

    if (x?.modification === "S") {
      window.location.href = "/";
    }
    window.location.reload();
  };

  fetchDevices = async () => {
    console.log(this.state.groups);
    let endpoint = "spc3_devices?owner=" + this.global.user?.data?.groups[0].id;

    try {
      axios
        .get(CONFIG.API_URL_V2 + endpoint, { validateStatus: false })
        .then(async (res) => {
          if (parseInt(res.data.STATUS_CODE) === 200) {
            await this.setState({ devices: res.data.DATA });
            if (
              res.data.DATA &&
              localStorage.getItem("selectedDevice") == null
            ) {
              await localStorage.setItem(
                "selectedDevice",
                JSON.stringify(res.data.DATA[0])
              );
            }
          }
        });
    } catch {
      this.timeout = null;
    }
  };

  handleLogout = () => {
    this.setGlobal({
      isLoggedIn: false,
      user: null,
    });

    localStorage.removeItem("isLoggedIn");
    localStorage.removeItem("user");
    localStorage.removeItem("selectedDevice");
    localStorage.removeItem("passwordChanged");
    localStorage.removeItem("initRefresh");

    this.props.history.push("/");
  };

  showPowerPage = async () => {
    await this.setState({ component: "power" });
    await this.setState({ route: this.props.location.pathname });
  };

  showUserPage = async () => {
    await this.setState({ component: "user" });
    await this.setState({ route: this.props.location.pathname });
  };

  showStatusPage = async () => {
    await this.setState({ component: "status" });
    await this.setState({ route: this.props.location.pathname });
  };

  showDebugPage = async () => {
    await this.setState({ component: "debug" });
    await this.setState({ route: this.props.location.pathname });
  };

  showSensorsPage = async () => {
    await this.setState({ component: "sensors" });
    await this.setState({ route: this.props.location.pathname });
  };

  showStatisticsPage = async () => {
    await this.setState({ component: "statistics" });
    await this.setState({ route: this.props.location.pathname });
  };

  showDashboardPage = async () => {
    await this.setState({ component: "dashboard" });
    await this.setState({ route: this.props.location.pathname });
  };

  showContactpage = async () => {
    await this.setState({ component: "contact-us" });
    await this.setState({ route: this.props.location.pathname });
  };

  render() {
    const { t } = this.props;

    return (
      <div>
        {/* Top Bar Start */}
        <div className="topbar">
          {this.global.isLoading && (
            <div className="loading">{t("Loading")}&#8230;</div>
          )}
          <nav className="topbar-main">
            {/* LOGO */}
            <div className="topbar-left">
              <Link to="/" className="logo" onClick={this.showDashboardPage}>
                <span>
                  {CONFIG.IS_A1 && (
                    <img
                      src="images/logo-a1.jpg"
                      alt="Bercman"
                      className="logo-sm img-responsive"
                    />
                  )}
                  {!CONFIG.IS_A1 && (
                    <img
                      src="images/logo-dark.png"
                      alt="Bercman"
                      className="logo-sm img-responsive"
                    />
                  )}
                </span>
              </Link>
            </div>
            {/*topbar-left*/}
            {/*end logo*/}
            <ul className="list-unstyled topbar-nav float-right mb-0">
              <li>
                {this.state.devices && (
                  <>
                    <a
                      className="nav-link dropdown-toggle waves-effect waves-light"
                      data-toggle="dropdown"
                      onClick={() =>
                        this.setState({ displayDeviceSelect: true })
                      }
                      href="javascript: void(0);"
                      role="button"
                      aria-haspopup="false"
                      aria-expanded="false"
                    >
                      {localStorage.getItem("selectedDevice") && (
                        <>
                          {
                            JSON.parse(localStorage.getItem("selectedDevice"))
                              .name
                          }
                        </>
                      )}
                      <i className="mdi mdi-chevron-down"></i>
                    </a>

                    {this.state.displayDeviceSelect && (
                      <div className="dropdown-menu dropdown-menu-right">
                        <div>
                          {this.state.devices.map((x, y) => (
                            <a
                              className="dropdown-item"
                              href="javascript: void(0);"
                              onClick={() => this.setSelectedDevice(x)}
                            >
                              <span>{x.name}</span>
                            </a>
                          ))}
                        </div>
                      </div>
                    )}
                  </>
                )}
              </li>
              <li>
                {i18n.language === "ee" && (
                  <a
                    className="nav-link dropdown-toggle waves-effect waves-light"
                    data-toggle="dropdown"
                    onClick={() => this.setState({ displayLangSelect: true })}
                    href="javascript: void(0);"
                    role="button"
                    aria-haspopup="false"
                    aria-expanded="false"
                  >
                    {t("Estonian")}{" "}
                    <img
                      src="images/flags/estonia_flag.png"
                      className="ml-2"
                      height="16"
                      width="24"
                      alt=""
                    />{" "}
                    <i className="mdi mdi-chevron-down"></i>
                  </a>
                )}
                {i18n.language === "en" && (
                  <a
                    className="nav-link dropdown-toggle waves-effect waves-light"
                    data-toggle="dropdown"
                    href="javascript: void(0);"
                    role="button"
                    onClick={() => this.setState({ displayLangSelect: true })}
                    aria-haspopup="false"
                    aria-expanded="false"
                  >
                    {t("English")}{" "}
                    <img
                      src="images/flags/us_flag.jpg"
                      className="ml-2"
                      height="16"
                      width="24"
                      alt=""
                    />{" "}
                    <i className="mdi mdi-chevron-down"></i>
                  </a>
                )}
                {i18n.language === "cr" && (
                  <a
                    className="nav-link dropdown-toggle waves-effect waves-light"
                    data-toggle="dropdown"
                    href="javascript: void(0);"
                    role="button"
                    onClick={() => this.setState({ displayLangSelect: true })}
                    aria-haspopup="false"
                    aria-expanded="false"
                  >
                    {t("Croatian")}{" "}
                    <img
                      src="images/flags/croatia-flag.jpg"
                      className="ml-2"
                      height="16"
                      width="24"
                      alt=""
                    />{" "}
                    <i className="mdi mdi-chevron-down"></i>
                  </a>
                )}
                {this.state.displayLangSelect && (
                  <div className="dropdown-menu dropdown-menu-right">
                    {i18n.language === "ee" && (
                      <div>
                        <a
                          className="dropdown-item"
                          href="javascript: void(0);"
                          onClick={() => this.changeLanguage("en")}
                        >
                          <span> {t("English")} </span>
                          <img
                            src="images/flags/us_flag.jpg"
                            alt=""
                            className="ml-2 float-right"
                            height="14"
                            width="21"
                          />
                        </a>
                        <a
                          className="dropdown-item"
                          href="javascript: void(0);"
                          onClick={() => this.changeLanguage("cr")}
                        >
                          <span> {t("Croatian")} </span>
                          <img
                            src="images/flags/croatia-flag.jpg"
                            alt=""
                            className="ml-2 float-right"
                            height="14"
                            width="21"
                          />
                        </a>
                      </div>
                    )}

                    {i18n.language === "en" && (
                      <div>
                        <a
                          className="dropdown-item"
                          href="javascript: void(0);"
                          onClick={() => this.changeLanguage("ee")}
                        >
                          <span> {t("Estonian")} </span>
                          <img
                            src="images/flags/estonia_flag.png"
                            alt=""
                            className="ml-2 float-right"
                            height="14"
                            width="21"
                          />
                        </a>
                        <a
                          className="dropdown-item"
                          href="javascript: void(0);"
                          onClick={() => this.changeLanguage("cr")}
                        >
                          <span> {t("Croatian")} </span>
                          <img
                            src="images/flags/croatia-flag.jpg"
                            alt=""
                            className="ml-2 float-right"
                            height="14"
                            width="21"
                          />
                        </a>
                      </div>
                    )}
                    {i18n.language === "cr" && (
                      <div>
                        <a
                          className="dropdown-item"
                          href="javascript: void(0);"
                          onClick={() => this.changeLanguage("ee")}
                        >
                          <span> {t("Estonian")} </span>
                          <img
                            src="images/flags/estonia_flag.png"
                            alt=""
                            className="ml-2 float-right"
                            height="14"
                            width="21"
                          />
                        </a>
                        <a
                          className="dropdown-item"
                          href="javascript: void(0);"
                          onClick={() => this.changeLanguage("en")}
                        >
                          <span> {t("English")} </span>
                          <img
                            src="images/flags/us_flag.jpg"
                            alt=""
                            className="ml-2 float-right"
                            height="14"
                            width="21"
                          />
                        </a>
                      </div>
                    )}
                  </div>
                )}
              </li>

              <li>
                <>
                  <a
                    className="nav-link dropdown-toggle waves-effect waves-light"
                    data-toggle="dropdown"
                    onClick={() => this.setState({ displaySettings: true })}
                    href="javascript: void(0);"
                    role="button"
                    aria-haspopup="false"
                    aria-expanded="false"
                  >
                    <i className="mdi mdi-settings"></i>{" "}
                    <i className="mdi mdi-chevron-down"></i>
                  </a>

                  {this.state.displaySettings && (
                    <div className="dropdown-menu dropdown-menu-right">
                      <div>
                        <a
                          className="dropdown-item"
                          href="javascript: void(0);"
                          onClick={() => this.setRefreshModelOpen()}
                        >
                          <span>{t("Change password")}</span>
                        </a>

                        <a
                          className="dropdown-item"
                          href="javascript: void(0);"
                          onClick={() => this.setSpeedModelOpen()}
                        >
                          <span>{t("Set speeding trigger")}</span>
                        </a>
                      </div>
                    </div>
                  )}
                </>
              </li>

               <li>
                <a
                  className="nav-link  waves-effect waves-light"
                  onClick={() => window.location.href='/notifications'}
                >
                  <Badge
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    overlap="circular"
                    color="secondary"
                    badgeContent={this.state.notificationCount}
                    style={{ transform: "translate(20px, -15px)" }}
                  ></Badge>
                  <i className="mdi mdi-bell" style={{ fontSize: "20px" }}></i>
                  <i className="mdi mdi-chevron-down"></i>
                </a>

              </li>

              {/*end dropdown*/}
              <li className="menu-item">
                {/* Mobile menu toggle*/}
                <a
                  className="navbar-toggle nav-link"
                  id="mobileToggle"
                  onClick={() =>
                    this.setState({
                      mobileMenuOpen: !this.state.mobileMenuOpen,
                    })
                  }
                >
                  <div className="lines">
                    <span />
                    <span />
                    <span />
                  </div>
                </a>
                {/* End mobile menu toggle*/}
              </li>
              {/*end menu item*/}
            </ul>
            {/*end topbar-nav*/}
          </nav>
          {/* end navbar*/}
          {/* MENU Start */}
          <div className={`navbar-custom-menu${CONFIG.IS_A1 ? " a1" : ""}`}>
            <div className="container-fluid">
              <div id="navigation">
                {/* Navigation Menu*/}
                <ul className="navigation-menu">
                  <li>
                    <a href="/">
                      <i
                        className="mdi mdi-desktop-mac-dashboard"
                        style={{ fontSize: "18px" }}
                      />
                      <span>{t("Dashboard")}</span>
                    </a>
                  </li>
                  <li>
                    <a href="/notifications">
                      <i
                          className="mdi mdi-bell"
                          style={{ fontSize: "18px" }}
                      />
                      <span>{t("Notifications")}</span>
                    </a>
                  </li>
                  {JSON.parse(localStorage.getItem("selectedDevice"))
                    ?.modification === "M" && (
                    <>
                      {this.state.modules.statistics > 0 && (
                        <li>
                          <a href="/statistics">
                            <i
                              className="mdi mdi-graphql"
                              style={{ fontSize: "18px" }}
                            />
                            <span>{t("Statistics")}</span>
                          </a>
                        </li>
                      )}
                      {this.state.modules.sensors > 0 && (
                        <li>
                          <a href="/sensors">
                            <i
                              className="mdi mdi-apps"
                              style={{ fontSize: "18px" }}
                            />
                            <span>{t("Sensors")}</span>
                          </a>
                        </li>
                      )}
                      {this.state.modules.power > 0 && (
                        <li>
                          <a href="/power">
                            <i
                              className="mdi mdi-power"
                              style={{ fontSize: "18px" }}
                            />
                            <span>{t("Power")}</span>
                          </a>
                        </li>
                      )}
                    </>
                  )}
                  {this.state.modules.status > 0 && (
                    <li>
                      <a href="/status">
                        <i
                          className="mdi mdi-power"
                          style={{ fontSize: "18px" }}
                        />
                        <span>{t("Status")}</span>
                      </a>
                    </li>
                  )}
                  {this.state.modules.debug > 0 && (
                    <li>
                      <a href="/debug">
                        <i
                          className="mdi mdi-bug"
                          style={{ fontSize: "18px" }}
                        />
                        <span>{t("Debug")}</span>
                      </a>
                    </li>
                  )}
                  <li>
                    <a href="/contact-us">
                      <i
                        className="mdi mdi-contacts"
                        style={{ fontSize: "18px" }}
                      />
                      <span>{t("Contact us")}</span>
                    </a>
                  </li>
                  {this.state.modules.users > 0 && (
                    <li>
                      <a href="/users">
                        <i
                          className="mdi mdi-account"
                          style={{ fontSize: "18px" }}
                        />
                        <span>{t("Users")}</span>
                      </a>
                    </li>
                  )}

                  {/*end has-submenu*/}
                  <li>
                    <a href="#" onClick={this.handleLogout}>
                      <i
                        className="mdi mdi-logout"
                        style={{ fontSize: "18px" }}
                      />
                      <span>{t("Log out")}</span>
                    </a>
                  </li>
                  {/*end has-submenu*/}
                </ul>
                {/* End navigation menu */}
              </div>
              {/* end navigation */}
            </div>
            {/* end container-fluid */}
          </div>
          {/* end navbar-custom */}

          {/* end navbar-custom */}
        </div>
        {/* Top Bar End */}
        <div className="page-wrapper">
          {/* Page Content*/}
          <div className="page-content">
            <div className="container-fluid">
              {/* Page-Title */}
              <div className="row">
                <div className="col-sm-12">
                  <div className="page-title-box">
                    <div className="float-right"></div>
                    {/*end /div*/}
                    <h4 className="page-title">
                      {t(this.state.title.replace("-", " "))}
                    </h4>
                  </div>
                  {/*end page-title-box*/}
                </div>
                {/*end col*/}
              </div>
              {/*end row*/}
              {/* end page title end breadcrumb */}
              {localStorage.getItem("selectedDevice") && (
                <>
                  {this.state.component === "dashboard" && <DashboardPage />}
                  {this.state.component === "statistics" && <StatisticsPage />}
                  {this.state.component === "sensors" && <SensorPage />}
                  {this.state.component === "power" && <PowerPage />}
                  {this.state.component === "contact-us" && <ContactPage />}
                  {this.state.component === "status" && <StatusPage />}
                  {this.state.component === "debug" && <DebugPage />}
                  {this.state.component === "users" && <UserPage />}
                  {this.state.component === "notifications" && <NotificationsPage />}
                </>
              )}
            </div>
          </div>
          {/* end page content */}
          <footer className="footer text-center text-sm-left">
            <div className="boxed-footer">
              © 2019-{new Date().getFullYear()} {t("Bercman Technologies AS")} |
              Build {CONFIG.VERSION}
            </div>
          </footer>
          {/*end footer*/}
        </div>
        <PasswordChangeModal
          isOpen={this.state.refreshModalOpen}
          toggleModal={this.setRefreshModelOpen}
          force={this.state.refreshPW}
          overrideForce={this.overrideForce}
          logout={this.handleLogout}
        />
        <SetSpeedModal
          isOpen={this.state.speedModalOpen}
          toggleModal={this.setSpeedModelOpen}
        />
        {/* end page-wrapper */}
      </div>
    );
  }
}

export default withNamespaces()(MainPage);
