export const TRANSLATION_EN = {
    "change_name":"Change name",
    "device_name":"Device name",
    "history_view":"History view",
    "daily_view":"Daily view",
    "choose_period":"Choose period",
    "beginning_of_the_year":"Beginning of the year",
    "last_3_months":"Last 3 months",
    "last_month":"Last month",
    "last_week":"Last week",
    "this_week":"This week",
    "total":"Total",
    "max_speed":"Max speed",
    "direction":"Direction",
    "pedestrian_statistics":"Pedestrian statistics",
    "radar_statistics":"Radar statistics",
    "export_data":"Export data",
    "air_quality":"Air quality",
    "air_pressure":"Air pressure",
    "temperature":"Temperature",
    "debug":"Debug",
    "users":"Users",
    "blink_device_a":"Blink device A",
    "blink_device_b":"Blink device B",
    "blink_ended_a":"Blink ended device A",
    "blink_ended_b":"Blink ended device B",
    "latest_boot_a":"Latest boot device A",
    "latest_boot_b":"Latest boot device B",
    "read_device_a":"SHT21 read device A",
    "read_device_b":"SHT21 read device B",

    dashboard : "Dashboard",
    statistics : "Statistics",
    sensors : "Sensors",
    power : "Power",
    contact_us : "Contact Us",
    log_out : "Log out",
    BMC : "BMC",
    introduction_to_bmc : "Introduction to BMC",
    intro_text : "BMC is the web-based user interface that allows You to access and manage data from Your Bercman's traffic management products, including the Smart Pedestrian Crosswalk (SPC) and the Smart Mobility Hub (SMH). To understand all of the information presented in the BMC, please read the following introduction that will provide you with an overview of BMC by walking You through the navigation of the console, its key features and how to get started using it. The introduction will also walk through different use case demonstrations of the BMC that give you a better understanding of how to make the most of the gathered data.\n \n If you require any further information, feel free to contact our team by e-mail support@bercman.com. We will provide an answer within 24 hours.\n \nStay safe and be noticed!\n \nTeam Bercman",
    pedestrian_count_direction_count_A_B : "Pedestrian Count Direction Count A->B",
    crossings_counted : "Crossing counted",
    pedestrian_count_direction_count_b_a : "Pedestrian Count Direction Count B->A",
    average_crossing_time : "Average Crossing Time",
    foul_crossing_a_b : "Foul Crossing A->B",
    foul_crossing_b_a : "Foul Crossing B->A",
    average_waiting_time : "Average Waiting Time",
    presence_trigger : "Presence Trigger",
    long_vehicle_restriction_area : "Long-Vehicle Restriction area",
    speed_trigger : "Speed Trigger",
    speedings : "Speedings (over 60km/h)",
    wrong_way_direction_trigger : "Wrong Way Direction Trigger",
    wrong_way_driving_detected : "Wrong way driving detected",
    precentile_speed : "85th Percentile Speed",
    radar_counting : "Radar Counting",
    pedestrian : "Pedestrian",
    bicycle : "Bicycle",
    motorbike : "Motorbike",
    passenger_car : "Passenger car",
    transporter : "Transporter",
    truck_bus : "Truck/Bus",
    long_truck : "Long truck",
    last_updated : "Last updated",
    road_temperature : "Road Temperature",
    light_level : "Light Level",
    internal_temperature : "Internal Temperature",
    internal_humidity : "Internal Humidity",
    battery_voltage : "Battery Voltage",
    input_voltage : "Input Voltage",
    input_amperage : "Input Amperage",
    input_power : "Input Power",
    fan_speed : "Fan Speed",
    voltage : "Voltage",
    frequency : "Frequency",
    active_power : "Active Power",
    reactive_power : "Reactive Power",
    apparent_power : "Apparent Power",
    power_factor : "Power Factor",
    active_energy : "Active Energy",
    total_kwh : "Total KW/H",
    reactive_energy : "Reactive Energy",
    management_console : "Management Console",
    contact_us_title : "If you have any problems, feel free to contact us!",
    fill_all_details : "Please fill all fields",
    name : "Name",
    email : "E-mail address",
    subject : "Subject",
    message : "Message",
    send_message : "Send message",
    message_sent : "Thank you! We will get back to you soon!",
    outdated : "Outdated",
    back: "Previous day",
    forward: "Next day",
    status: "Status",

    stat_1 : "The number of pedestrians who came from the [A] side of the crosswalk and went to [B] side of the crosswalk from beginning.",
    stat_2 : "The number of pedestrians who came from the [B] side of the crosswalk and went to [A] side of the crosswalk from beginning.",
    stat_3 : "On average, pedestrians cross the street in this many seconds in the last [X]. Averaged for both ways.",
    stat_4 : "The number of pedestrians who came from the [A] side of the crosswalk and performed a foul crossing towards the [B] side of the crosswalk from beginning.",
    stat_5 : "The number of pedestrians who came from the [B] side of the crosswalk and performed a foul crossing towards the [A] side of the crosswalk from beginning.",
    stat_6 : "On average, pedestrians waited before crossing the street for this many seconds from beginning. Averaged for both ways.",
    stat_7 : "Presence of a certain type of road users has been detected this many times from beginning",
    stat_8 : "The amount of speedings detected from beginning.",
    stat_9 : "The amount of wrong way driving fouls detected from beginning.",
    stat_10 : "The speed at or below which 85 percent of all vehicles were observed to travel under free-flowing conditions from beginning.",

    sensors_1 : "The road temperature measured by an infrared thermometer.",
    sensors_2 : "Environmental diffused light level.",
    sensors_3 : "Internal temperature of the device.",
    sensors_4 : "Internal relative humidity of the device.",
    sensors_5 : "Voltage level of the battery (max voltage 14.4 V).",
    sensors_6 : "Voltage level of the solar panel (max voltage 19.6 V).",
    sensors_7 : "Amperage level of the solar panel (max amperage 4.59 A).",
    sensors_8 : "Power level produced by the solar panel (max power 90 W).",
    sensors_9 : "Input voltage provided to the main control board by the power supply unit (12 V nominal).",

    power_1 : "The amount of ampers used by the device.",
    power_2 : "The power consumption of the device.",
    power_3 : "Speed level of the fan, where 0% means a completely stopped fan and 100% a fan blowing at its full strength.",
    power_4 : "Mains power alternating voltage level (VAC)",
    power_5 : "Mains power alternating current level (AAC)",
    power_6 : "Mains power alternating voltage frequency (50 Hz nominal).",
    power_7 : "The power which is actually consumed or utilised in an AC Circuit is called True power or Active power or Real power. It is measured in watts (W). It is the actual outcomes of the electrical system which runs the electric circuits or load.",
    power_8 : "The power which flows back and forth that means it moves in both the directions in the circuit or reacts upon itself, is called Reactive Power and measured in var.",
    power_9 : "The product of root mean square (RMS) value of voltage and current is known as Apparent Power. This power is measured in volt-amperes (VA).",
    power_10 : "Power factor is the measure of how efficiently incoming power is used in an electrical installation. It is the ratio of active to apparent power.",
    power_11 : "The electric receivers powered by electric current transform the energy into mechanical work and heat. This useful effect is called “active energy” and is measured in kWh. The receivers formed by purely resistive circuits (heaters, incandescent light bulb, etc.) only use this type of energy.",
    power_12 : "Total amount of mains power energy used in kWh.",
    power_13 : "There are several receivers, such as motors, transformer, reactance, etc., that need magnetic fields in order to work. These equipments are generally inductive, they absorb energy from the network in order to create magnetic fields and return it while they disappear. With this exchange of energy, an additional consumption is used that it's not useful for the receivers. This energy is called “reactive” and it's measured in var-hours.",
    radar_options: {
        chart: {
            id: "basic-bar"
        },
        xaxis: {
            categories: ['Bicycle', 'Motorbike', 'Passenger Car', 'Transporter', 'Truck/Bus', 'Long truck']
        }
    },

    optionsPie: {
        chart: {
            width: 380,
            type: 'pie',
        },
        labels: ['Bicycle', 'Motorbike', 'Passenger Car', 'Transporter', 'Truck/Bus', 'Long truck'],

    },
    locale: "en",
};
