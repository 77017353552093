import React, {Component, useGlobal, useEffect} from 'reactn';
import axios from 'axios';
import {CONFIG} from "../../Constants/config";
import FancyCard from "../../Components/FancyCard/FancyCard";
import Moment from "react-moment";
import * as moment from "moment-timezone";
import {TRANSLATION_EE} from "../../Constants/translation_ee";
import {TRANSLATION_EN} from "../../Constants/translation_en";
import 'moment/locale/et';
import {TRANSLATION_CR} from "../../Constants/translation_cr";
import {withNamespaces} from "react-i18next";
class StatusPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            expanded: false,
            status: [],
            lastUpdated: null,
            outOfSync: false,
            translations: [],
            timeZone: null
        };

        Moment.globalFormat = 'YYYY-M-D H:m:ss';
    };


    async componentDidMount() {
        this.setGlobal({'isLoading': true});
        this.fetchData();

        if(localStorage.getItem('lang') === "EE") {
            this.setState({translations: TRANSLATION_EE});
        } else if(localStorage.getItem('lang') === "CR") {
            this.setState({translations: TRANSLATION_CR});
        } else {
            this.setState({translations: TRANSLATION_EN})
        }

        let timezoneData = await Intl.DateTimeFormat().resolvedOptions();

        console.log(timezoneData);
        console.log(timezoneData.timeZone);

        moment.tz.setDefault(timezoneData.timeZone);
    }

    fetchData = () => {
        if(!localStorage.getItem('selectedDevice')) {return}
        let endpoint = 'spc3_status?uuid=' + JSON.parse(localStorage.getItem('selectedDevice')).UUID;

        try {
            axios.get(CONFIG.API_URL_V2 + endpoint, { validateStatus: false })
                .then(res => {
                    if (parseInt(res.data.STATUS_CODE) === 200) {

                        this.setState({status: res.data.DATA});

                        //console.log(res.data.DATA);

                        for (let key in res.data.DATA.A) {
                            // if key starts with rpi_
                            if (key.startsWith('rpi_')) {
                                // check if the value is null
                                if (res.data.DATA.A[key] !== null) {
                                    let holder = moment(res.data.DATA.A[key]).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('Z');
                                    holder.replaceAll('0', holder);
                                    holder.replaceAll(':', holder);

                                    //add hours moment
                                    res.data.DATA.A[key] = moment(res.data.DATA.A[key]).add(holder, 'hours').format('DD.MM.YYYY HH:mm:ss');

                                }
                            }
                        }

                        for (let key in res.data.DATA.B) {
                            // if key starts with rpi_
                            if (key.startsWith('rpi_')) {
                                // check if the value is null
                                if (res.data.DATA.B[key] !== null) {
                                    let holder = moment(res.data.DATA.B[key]).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('Z');
                                    holder.replaceAll('0', holder);
                                    holder.replaceAll(':', holder);

                                    //add hours moment
                                    res.data.DATA.B[key] = moment(res.data.DATA.B[key]).add(holder, 'hours').format('DD.MM.YYYY HH:mm:ss');

                                }
                            }
                        }

                        this.setState({lastUpdated: res.data.DATA.created_at});
                        this.setGlobal({'isLoading': false});

                        var d1 = new Date(Date.now() - 1000 * CONFIG.CRITICAL_UPDATE_TIME);
                        var d2 = new Date(this.state.lastUpdated);


                        if (d2 < d1) {
                            this.state.outOfSync = true;
                        } else {
                            this.state.outOfSync = false;
                        }

                        setTimeout(this.fetchData, 5000);
                    }
                });
        } catch {
            this.timeout = null;
        }


    };

    render() {
        const {t} = this.props;

        return (
            <div>
                <div className="row mb-0 row-eq-height">
                    <FancyCard
                        size={6}
                        title={t("Blink device A")}
                        value={(this.state.status?.A?.rpi_blink ? this.state.status.A.rpi_blink : 0)}
                    />
                    <FancyCard
                        size={6}
                        title={t("Blink device B")}
                        value={(this.state.status?.B?.rpi_blink ? this.state.status.B.rpi_blink : 0)}

                    />
                    <FancyCard
                        size={6}
                        title={t("Blink ended device A")}
                        value={(this.state.status?.A?.rpi_blink_ended ? this.state.status.A.rpi_blink_ended : 0)}

                    />
                    <FancyCard
                        size={6}
                        title={t("Blink ended device B")}
                        value={(this.state.status?.B?.rpi_blink_ended ? this.state.status.B.rpi_blink_ended : 0)}
                    />
                    <FancyCard
                        size={6}
                        title={t("Latest boot device A")}
                        value={(this.state.status?.A?.rpi_nodejs_starting ? this.state.status.A.rpi_nodejs_starting : 0)}

                    />
                    <FancyCard
                        size={6}
                        title={t("Latest boot device B")}
                        value={(this.state.status?.B?.rpi_nodejs_starting ? this.state.status.B.rpi_nodejs_starting : 0)}

                    />
                    <FancyCard
                        size={6}
                        title={t("SHT21 read device A")}
                        value={(this.state.status?.A?.rpi_SHT21_read ? this.state.status.A.rpi_SHT21_read : 0)}

                    />
                    <FancyCard
                        size={6}
                        title={t("SHT21 read device B")}
                        value={(this.state.status?.B?.rpi_SHT21_read ? this.state.status.B.rpi_SHT21_read : 0)}

                    />
                </div>
                {this.state.translations.locale &&
                <div className="row mb-0 row-eq-height">
                    {this.state.sensors &&
                    <div
                        className={"col-xl-12 text-right " + (this.state.outOfSync ? 'text-danger' : '')}>
                        {this.state.translations.last_updated}: {moment(this.state.status.created_at).locale(this.state.translations.locale).fromNow()} {this.state.outOfSync && this.state.translations.outdated}
                    </div>
                    }
                </div>
                }
            </div>
        );
    }
}

export default withNamespaces()(StatusPage);
