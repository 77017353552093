import React, { setGlobal } from 'reactn';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import './i18n';

setGlobal({
    isLoggedIn: localStorage.getItem('isLoggedIn'),
    user: JSON.parse(localStorage.getItem('user')),
    isLoading: false
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);


