import React, {Component} from 'reactn';
import axios from 'axios';
import {CONFIG} from "../../Constants/config";
import DataTable from 'react-data-table-component';
import {withNamespaces} from "react-i18next";
import swal from 'sweetalert';
import {Select2} from "select2-react-component";

class UserPage extends Component {
    constructor(props) {
        super(props);

        this.state = {

            groupNameEdit: null,
            groupIdEdit: null,
            groupDescriptionEdit: null,
            groupModulesEdit: null,
            groupDevicesEdit: null,

            userIdEdit: null,
            userEmailEdit: null,
            userPasswordEdit: null,
            userPassword2Edit: null,
            userFirstnameEdit: null,
            userLastnameEdit: null,
            userDescriptionEdit: null,
            userGroupEdit: null,

            devices: null,
            groups: null,
            users: null,
            expanded: false,
            status: [],
            lastUpdated: null,
            outOfSync: false,
            translations: [],
            devicesSelect2: [],
            modulesSelect2: [
                {
                    label: props.t("Statistics"),
                    value: 1
                },
                {
                    label: props.t("Sensors"),
                    value: 2
                },
                {
                    label: props.t("Power"),
                    value: 3
                },
                {
                    label: props.t("Status"),
                    value: 4
                },
                {
                    label: props.t("Debug"),
                    value: 5
                },
                {
                    label: props.t("Contact us"),
                    value: 6
                }
            ],

            columnsUser: [
                {
                    name: props.t("Email"),
                    selector: row => row.email,
                    sortable: true,
                },
                {
                    name: props.t("First name"),
                    selector: row => row.first_name,
                    sortable: true,
                },
                {
                    name: props.t("Last name"),
                    selector: row => row.last_name,
                    sortable: true,
                },
                {
                    name: props.t("Description"),
                    selector: row => row.description,
                    sortable: true,
                },
                {
                    name: props.t("Groups"),
                    selector: row => row.groups,
                    sortable: true,
                },
                {
                    name: props.t("Actions"),
                    selector: row => row.actions,
                    sortable: false,
                    right: true
                }
            ],

            dataUser: [],

            columnsGroups: [
                {
                    name: props.t("Group name"),
                    selector: row => row.name,
                    sortable: true,
                },
                {
                    name: props.t("Description"),
                    selector: row => row.description,
                    sortable: true,
                },
                {
                    name: props.t("Actions"),
                    selector: row => row.actions,
                    sortable: false,
                    right: true
                }
            ],

            dataGroups: []
        };
    };

    ///
    handleUserEmailChange = (event) => {
        this.setState({userEmailEdit: event.target.value});
    }
    handleUserPasswordChange = (event) => {
        this.setState({userPasswordEdit: event.target.value});
    }
    handleUserPassword2Change = (event) => {
        this.setState({userPassword2Edit: event.target.value});
    }
    handleUserFirstnameChange = (event) => {
        this.setState({userFirstnameEdit: event.target.value});
    }
    handleUserLastnameChange = (event) => {
        this.setState({userLastnameEdit: event.target.value});
    }
    handleUserDescriptionChange = (event) => {
        this.setState({userDescriptionEdit: event.target.value});
    }
    handleUserGroupChange = (event) => {
        this.setState({userGroupEdit: event.target.value});
    }
    ///

    handleGroupNameChange = (event) => {
        this.setState({groupNameEdit: event.target.value});
    }

    handleGroupDescriptionChange = (event) => {
        this.setState({groupDescriptionEdit: event.target.value});
    }

    handleGroupModulesChange = (items) => {
        this.setState({groupModulesEdit: items});
    }

    handleHandleGroupDevicesChange = (items) => {
        this.setState({groupDevicesEdit: items});
    }

    saveUser = () => {
        axios.post(CONFIG.API_URL_V2 + "new_user", {
                'id': this.state.userIdEdit,
                'email': this.state.userEmailEdit,
                'password': this.state.userPasswordEdit,
                'password2': this.state.userPassword2Edit,
                'first_name': this.state.userFirstnameEdit,
                'last_name': this.state.userLastnameEdit,
                'descripion': this.state.userDescriptionEdit,
                'group': this.state.userGroupEdit,
            }
        ).then((res) => {
            if (res.data.STATUS_MESSAGE == "FAIL") {
                swal(this.props.t("Error!"), this.props.t(res.data.DATA.message), "error").then(r => {

                });
            } else {
                swal(this.props.t("Success!"), this.props.t("User saved!"), "success").then(r => {
                    this.fetchUsers();
                    this.userModalCloseButton.click();
                });

            }
        });
    }

    saveGroup = () => {
        // Do checks

        let name = this.state.groupNameEdit ? this.state.groupNameEdit.trim() : "";

        if (name.length <= 0) {
            swal(this.props.t("Error!"), this.props.t("Group name cannot be empty!"), "error").then(r => {
                // TODO paint input red
            });

            return;
        }

        if (!this.state.groupModulesEdit) {
            swal(this.props.t("Error!"), this.props.t("Modules cannot be empty!"), "error").then(r => {
                // TODO paint input red
            });

            return;
        }

        if (!this.state.groupDevicesEdit) {
            swal(this.props.t("Error!"), this.props.t("Devices cannot be empty!"), "error").then(r => {
                // TODO paint input red
            });

            return;
        }


        axios.post(CONFIG.API_URL_V2 + "new_group", {
                'id': this.state.groupIdEdit,
                'name': this.state.groupNameEdit,
                'description': this.state.groupDescriptionEdit,
                'modules': this.state.groupModulesEdit,
                'devices': this.state.groupDevicesEdit,
                'master_group': this.global.user?.data?.groups[0].id
            }
        ).then((res) => {
            if (res.data.STATUS_MESSAGE == "FAIL") {
                swal(this.props.t("Error!"), this.props.t(res.data.DATA.message), "error").then(r => {
                    // TODO paint input red
                });
            } else {
                swal(this.props.t("Success!"), this.props.t("Group saved!"), "success").then(r => {
                    this.fetchGroups();
                    this.groupModalCloseButton.click();
                });

            }
        });
    }


    componentDidMount() {
        this.fetchGroups();
        this.fetchUsers();
    }

    fetchData = () => {
        this.setGlobal({'isLoading': true});
    };

    fetchDevices = () => {

        console.log(this.state.groups);
        let endpoint = 'spc3_devices?owner=' + this.global.user?.data?.groups[0].id;

        try {
            axios.get(CONFIG.API_URL_V2 + endpoint, {validateStatus: false})
                .then(res => {
                    if (parseInt(res.data.STATUS_CODE) === 200) {
                        this.setState({'devices': res.data.DATA});

                        let devicesSelect2 = [];

                        res.data.DATA.map((x, y) => {
                            devicesSelect2.push({value: x.id, label: x.name});
                        });

                        this.setState({'devicesSelect2': devicesSelect2});

                    }
                });
        } catch {
            this.timeout = null;
        }
    }

    fetchUsers = () => {
        let endpoint = 'getUserWithGroups?id=' + this.global.user?.data?.groups[0].id;

        try {
            axios.get(CONFIG.API_URL_V2 + endpoint, {validateStatus: false})
                .then(res => {
                    if (parseInt(res.data.STATUS_CODE) === 200) {
                        this.setState({'users': res.data.DATA});

                        let newArray = [];

                        Object.keys(res.data.DATA).forEach(key => {
                            let obj = res.data.DATA[key];
                            if (obj.id == this.global.user?.data?.id) {
                                newArray.push({
                                    id: obj.id,
                                    email: obj.email,
                                    first_name: obj.first_name,
                                    last_name: obj.last_name,
                                    description: obj.description,
                                    groups: obj.group_name,
                                })
                            } else {
                                newArray.push({
                                    id: obj.id,
                                    email: obj.email,
                                    first_name: obj.first_name,
                                    last_name: obj.last_name,
                                    description: obj.description,
                                    groups: obj.group_name,
                                    actions: <span><button className="btn btn-danger"
                                                           onClick={() => this.deleteUser(obj.id)}><i
                                        className="mdi mdi-delete"></i> </button>
                                        &nbsp;
                                        <button className="btn btn-info" onClick={() => this.editUser(obj.id)}
                                                data-toggle="modal"
                                                data-target="#userModal"><i
                                            className="mdi mdi-pencil"></i> </button></span>
                                })
                            }
                        });

                        this.setState({dataUser: newArray});
                    }
                });
        } catch {
            this.timeout = null;
        }
    }

    deleteGroup = (id) => {
        swal({
            title: this.props.t("Are you sure?"),
            text: this.props.t("Group and all associated users will be deleted!"),
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    axios.post(CONFIG.API_URL_V2 + "delete_group", {
                            'id': id,
                        }
                    ).then((res) => {
                        this.fetchGroups();
                        this.fetchUsers();
                        swal("Group deleted", {
                            icon: "success",
                        });
                    });

                } else {

                }
            });
    }

    deleteUser = (id) => {
        swal({
            title: this.props.t("Are you sure?"),
            text: this.props.t("User will be deleted!"),
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    axios.post(CONFIG.API_URL_V2 + "delete_user", {
                            'id': id,
                        }
                    ).then((res) => {
                        this.fetchGroups();
                        this.fetchUsers();
                        swal("User deleted", {
                            icon: "success",
                        });
                    });

                } else {

                }
            });
    }

    editGroup = (id) => {
        try {
            axios.get(CONFIG.API_URL_V2 + "get_group?id=" + id, {validateStatus: false})
                .then(res => {
                    if (parseInt(res.data.STATUS_CODE) === 200) {

                        let modules = [];
                        let devices = [];
                        Object.keys(res.data.DATA.devices).forEach(key => {
                            devices.push(res.data.DATA.devices[key].device_id);
                        });

                        let modulesSelection = res.data.DATA.modules;

                        if (modulesSelection.debug) {
                            modules.push(5)
                        }

                        if (modulesSelection.power) {
                            modules.push(3)
                        }

                        if (modulesSelection.sensors) {
                            modules.push(2)
                        }

                        if (modulesSelection.statistics) {
                            modules.push(1)
                        }

                        if (modulesSelection.status) {
                            modules.push(4)
                        }

                        if (modulesSelection.contact_us) {
                            modules.push(6)
                        }

                        console.log("DEVICES ", devices);
                        console.log("MODULES ", modules);
                        this.setState({
                            groupModulesEdit: modules,
                            groupDevicesEdit: devices,
                            groupNameEdit: res.data.DATA.group.name,
                            groupIdEdit: id,
                            groupDescriptionEdit: res.data.DATA.group.description,

                        });
                    }
                });
        } catch {
            this.timeout = null;
        }
    }

    editUser = (id) => {
        try {
            axios.get(CONFIG.API_URL_V2 + "get_user?id=" + id, {validateStatus: false})
                .then(res => {
                    if (parseInt(res.data.STATUS_CODE) === 200) {
                        console.log(res.data.DATA);
                        /**
                         * ompany_name: "A1"
                         * created_at: null
                         * description: "test"
                         * display_name: "test test"
                         * email: "test"
                         * first_name: "test"
                         * id: 15
                         * last_login: null
                         * last_name: "test"
                         * password: "$2y$10$qqSEBklnCPIhZy06HpcwNuTJ8Y11bX/F/sZx./q0bud9zmQDJigrm"
                         * token: "$2y$10$n5KwTEe5lXSVUcPwnAnWLOmtJErj08dZtcojFd0aPXIL.icT.VqSu"
                         * updated_at: null
                         */
                        this.setState({
                            userIdEdit: res.data.DATA.user.id,
                            userEmailEdit: res.data.DATA.user.email,
                            userPasswordEdit: null,
                            userFirstnameEdit: res.data.DATA.user.first_name,
                            userLastnameEdit: res.data.DATA.user.last_name,
                            userDescriptionEdit: res.data.DATA.user.description,
                            userGroupEdit: res.data.DATA.group.group_id
                        });
                    }
                });
        } catch {
            this.timeout = null;
        }
    }

    fetchGroups = () => {
        let endpoint = 'spc3_allGroups?id=' + this.global.user?.data?.groups[0].id;

        try {
            axios.get(CONFIG.API_URL_V2 + endpoint, {validateStatus: false})
                .then(res => {

                    if (parseInt(res.data.STATUS_CODE) === 200) {
                        console.log(res.data.DATA);
                        this.setState({'groups': res.data.DATA});
                        this.fetchDevices();
                        let newArray = [];

                        Object.keys(res.data.DATA).forEach(key => {
                            let obj = res.data.DATA[key];
                            this.setState({userGroupEdit: obj.id});

                            if (obj.id == this.global.user?.data?.groups[0].id) {
                                newArray.push({
                                    id: obj.id,
                                    name: obj.name,
                                    description: obj.description
                                })
                            } else {
                                newArray.push({
                                    id: obj.id,
                                    name: obj.name,
                                    description: obj.description,
                                    actions: <span><button className="btn btn-danger"
                                                           onClick={() => this.deleteGroup(obj.id)}><i
                                        className="mdi mdi-delete"></i> </button>
                                        &nbsp;
                                        <button className="btn btn-info" onClick={() => this.editGroup(obj.id)}
                                                data-toggle="modal"
                                                data-target="#groupModal"><i
                                            className="mdi mdi-pencil"></i> </button></span>
                                })
                            }
                        });

                        this.setState({dataGroups: newArray});


                    }
                });
        } catch {
            this.timeout = null;
        }
    }


    render() {
        const {t} = this.props;
        return (
            <div>
                <div className="row">
                    <div className="col-9">
                        <h4>{t("Users")}</h4>
                    </div>
                    <div className="col-3">
                        <div className="text-right">
                            <button type="button" className="btn btn-success" data-toggle="modal"
                                    data-target="#userModal" onClick={() => { console.log("asd");
                                this.setState({
                                    userIdEdit: '',
                                    userEmailEdit: '',
                                    userPasswordEdit: '',
                                    userFirstnameEdit: '',
                                    userLastnameEdit: '',
                                    userDescriptionEdit: ''
                                });

                            }}>
                                <i className="mdi mdi-plus"/> {t("Add user")}
                            </button>
                        </div>
                    </div>
                </div>
                <DataTable
                    columns={this.state.columnsUser}
                    data={this.state.dataUser}
                />
                <br/>
                <br/>

                <div className="row">
                    <div className="col-9">
                        <h4>{t("Groups")}</h4>
                    </div>
                    <div className="col-3">
                        <div className="text-right">
                            <button type="button" className="btn btn-success" data-toggle="modal"
                                    data-target="#groupModal" onClick={async () => await this.setState({
                                groupNameEdit: '',
                                groupIdEdit: '',
                                groupDescriptionEdit: '',
                                groupModulesEdit: '',
                                groupDevicesEdit: ''
                            })}>
                                <i className="mdi mdi-plus"/> {t("Add group")}
                            </button>
                        </div>
                    </div>
                </div>
                <DataTable
                    columns={this.state.columnsGroups}
                    data={this.state.dataGroups}
                />

                <div className="modal fade" id="userModal" tabIndex="-1" role="dialog"
                     aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">{t("Users")}</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <form>
                                    <div className="form-group">
                                        <label htmlFor="exampleFormControlInput1">
                                            {t("Email")}</label>
                                        <input type="email" className="form-control" id="exampleFormControlInput1"
                                               value={this.state.userEmailEdit} onChange={this.handleUserEmailChange}
                                               placeholder=""/>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="exampleFormControlInput1">
                                            {t("Password")}</label>
                                        <input type="password" className="form-control" id="exampleFormControlInput1"
                                               value={this.state.userPasswordEdit}
                                               onChange={this.handleUserPasswordChange}
                                               placeholder=""/>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="exampleFormControlInput1">
                                            {t("Confirm password")}</label>
                                        <input type="password" className="form-control" id="exampleFormControlInput1"
                                               value={this.state.userPassword2Edit}
                                               onChange={this.handleUserPassword2Change}
                                               placeholder=""/>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="exampleFormControlInput1">
                                            {t("First name")}</label>
                                        <input type="email" className="form-control" id="exampleFormControlInput1"
                                               value={this.state.userFirstnameEdit}
                                               onChange={this.handleUserFirstnameChange}
                                               placeholder=""/>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="exampleFormControlInput1">
                                            {t("Last name")}</label>
                                        <input type="email" className="form-control" id="exampleFormControlInput1"
                                               value={this.state.userLastnameEdit}
                                               onChange={this.handleUserLastnameChange}
                                               placeholder=""/>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="exampleFormControlTextarea1">{t("Description")}</label>
                                        <textarea className="form-control" id="exampleFormControlTextarea1"
                                                  value={this.state.userDescriptionEdit}
                                                  onChange={this.handleUserDescriptionChange}
                                                  rows="3"></textarea>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="exampleFormControlSelect2">{t("Select group")}</label>
                                        <select className="form-control" id="exampleFormControlSelect2"
                                                value={this.state.userGroupEdit} onChange={this.handleUserGroupChange}>
                                            {this.state.groups &&
                                                this.state.groups.map((x, y) =>
                                                    <option value={x.id}>{x.name}</option>)

                                            }
                                        </select>
                                    </div>

                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" ref={input => this.userModalCloseButton = input} className="btn btn-secondary" data-dismiss="modal">{t("Cancel")}
                                </button>
                                <button type="button" className="btn btn-primary"

                                        onClick={this.saveUser}>{t("Save user")}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="groupModal" tabIndex="-1" role="dialog"
                     aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">{t("Groups")}</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <form>
                                    <div className="form-group">
                                        <label htmlFor="exampleFormControlInput1">
                                            {t("Group name")}</label>
                                        <input type="email" className="form-control" id="exampleFormControlInput1"
                                               placeholder="" value={this.state.groupNameEdit}
                                               onChange={this.handleGroupNameChange}/>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="exampleFormControlTextarea1">{t("Description")}</label>
                                        <textarea className="form-control" id="exampleFormControlTextarea1"
                                                  rows="3" value={this.state.groupDescriptionEdit}
                                                  onChange={this.handleGroupDescriptionChange}></textarea>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="exampleFormControlSelect2">{t("Allowed modules")}</label>
                                        <Select2 data={this.state.modulesSelect2}
                                                 value={this.state.groupModulesEdit}
                                                 update={value => this.handleGroupModulesChange(value)}
                                                 multiple={true}
                                        >
                                        </Select2>
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="exampleFormControlSelect2">{t("Devices")}</label>
                                        <Select2 data={this.state.devicesSelect2}
                                                 value={this.state.groupDevicesEdit}
                                                 update={value => this.handleHandleGroupDevicesChange(value)}
                                                 multiple={true}
                                        >
                                        </Select2>

                                    </div>

                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal"
                                        ref={input => this.groupModalCloseButton = input}>{t("Cancel")}
                                </button>
                                <button type="button" className="btn btn-primary"
                                        onClick={this.saveGroup}>{t("Save group")}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withNamespaces()(UserPage);
