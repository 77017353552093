import React, {Component} from "reactn";
import "./LoginPage.css";
import {LogInQuery} from "../../Queries/LogInQuery";
import {CONFIG} from "../../Constants/config";

class LoginPage extends Component {
    constructor(props){
        super(props);
        this.state={
            email:'',
            password:'',
            showError: false,
            errorMessage: ''
        }
    }

    handleKeypress = (e) => {
        //it triggers by pressing the enter key
        if (e.keyCode === 13) {
            this.handleSubmit();
        }
    };

    handleEmailChange = (event) => {
        this.setState({email: event.target.value});
    };

    handlePasswordChange = (event) => {
        this.setState({password: event.target.value});
    };

    componentDidMount() {
        document.body.classList.add("account-body");
        document.body.classList.add("login-page");

        if(localStorage.getItem('isLoggedIn')) {
            this.props.history.push("/");
        }
    }

    componentWillUnmount() {
        document.body.classList.remove("account-body");
        document.body.classList.remove("login-page");
    }

    handleSubmit = async () => {
        try {
            const response = await LogInQuery(this.state.email, this.state.password);

            if(response && parseInt(response.status) === 200) {
                this.setGlobal({
                    'isLoggedIn': true,
                    'user': response.data
                });

                localStorage.setItem('isLoggedIn', true);
                console.log(response.data);
                localStorage.setItem('user', JSON.stringify(response.data));

                window.location.href = "/";
            } else if(response && parseInt(response.status) === 201){
                console.log("siin");
                this.setState({errorMessage: response.data.error_message});
                this.setState({showError: true});
            }
        } catch (e) {
            this.setState({showError: true});
        }

    };

    render() {
        return (
            <div className={`login-color${CONFIG.IS_A1 ? "-a1" : ""}`} >
                <div className="row vh-100 ">
                    <div className="col-12 align-self-center login-box">
                        <div className="auth-page">
                            <div className="card auth-card shadow-lg">
                                <div className="card-body">
                                    <div className="px-3">
                                        <div className="auth-logo-box">
                                            <a href="/" className="logo logo-admin">
                                                {CONFIG.IS_A1 &&
                                                    <img src="images/logo-a1.jpg"
                                                         height="81" alt="logo"
                                                         className="auth-logo"/>
                                                }
                                                {!CONFIG.IS_A1 &&
                                                    <img src="images/logo-sm.png"
                                                         height="81" alt="logo"
                                                         className="auth-logo"/>
                                                }
                                                </a>

                                        </div>
                                        <div className="text-center auth-logo-text">
                                            <h4 className="mt-0 mb-3 mt-5">Ready to manage?</h4>
                                            <p className="text-muted mb-0">Sign in to continue to the management
                                                Console</p>
                                            {this.state.showError > 0 &&
                                            <div className="alert alert-outline-danger alert-danger-shadow mb-0 my-4"
                                                 role="alert">
                                                {this.state.errorMessage}
                                            </div>
                                            }
                                        </div>
                                        <br/>
                                        <div className="form-group">
                                            <label htmlFor="username">E-mail</label>
                                            <div className="input-group mb-3">
                                            <span className="auth-form-icon">
                                                <i className="dripicons-user"/>
                                            </span>
                                                <input autoFocus type="text" name="email" className="form-control" onKeyDown={this.handleKeypress}
                                                       id="email" value={this.state.email} onChange={this.handleEmailChange}
                                                       placeholder="Enter e-mail"/>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="userpassword">Password</label>
                                            <div className="input-group mb-3">
                                            <span className="auth-form-icon">
                                                <i className="dripicons-lock"/>
                                            </span>
                                                <input type="password" name="password" className="form-control" onKeyDown={this.handleKeypress}
                                                       id="userpassword" placeholder="Enter password" value={this.state.password} onChange={this.handlePasswordChange}
                                                       autoComplete="on"/>
                                            </div>
                                        </div>
                                        <div className="form-group mb-0 row">
                                            <div className="col-12 mt-2">
                                                <button onClick={this.handleSubmit}
                                                    className="btn btn-primary btn-round btn-block waves-effect waves-light">Log In <i className="fas fa-sign-in-alt ml-1"/>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default LoginPage;
